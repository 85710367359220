<template>
	<div id="news" class="news" ref="news">
		<!-- 头部 nav导航 -->
		<header-nav :tab="true"></header-nav>
		<!-- 新闻资讯-->
		<div class="content-box">
			<!-- 小屏 -->
			<div class="news-logo" v-if="is_vertical">
				<img src="/images/news-bg.png" style="width: 100%;height: 100%;"/>
			</div>
			<div class="news-logo-top">
				<div class="c1">新闻中心</div>
				<div class="blue-line"></div>
				<div class="news-list" v-for="(item,index) in newsList" :key="index" @click="goDetail(item)">
					<div class="news-img">
						<img :src="item.thumbnail" style="width: 100%;height: 100%;"/>
					</div>
					<div>
						<div class="t2">{{item.title}}</div>
						<div class="t3">{{item.summary}}</div>
						<div class="c2">{{item.createTime}}</div>
					</div>
				</div>
				
				<div class="foot-page">
					<div class="page-box" @click="goList(-1)">
						<img src="/images/left.png" style="width: 50%;height: 50%;"/>
					</div>
					<div class="page-num" v-for="(item,index) in totalPages" :key="index" v-if="index<5" @click="goList(index+1)">{{index+1}}</div>
					<div class="page-box" @click="goList(-2)">
						<img src="/images/right.png" style="width: 50%;height: 50%;"/>
					</div>
				</div>
			</div>
		</div>
		
		<!-- 底部链接 -->
		<!-- 友情链接 -->
		<section class="friend uni-flex-column uni-flex-center" style="margin-top: 30px;">
			<div class="friend-content">
				<div class="friend-text">
					<span>友情链接：</span>
					<a href="https://shop117115684.taobao.com/?spm=a2142.8996326.sellercard.15.146f2be3VrqbMR">千雪制冷淘宝店</a>
				</div>
			</div>
		</section>
		<!-- 联系我们 -->
		<!-- 小屏 -->
		<section class="foot uni-flex-column uni-flex-center" ref="contact" v-if="is_vertical">
			<div class="foot-content">
				<div class="foot-logo">
					<img src="/images/qrcode.png" style="width: 100%;height: 100%;"/>
				</div>
				<div class="foot-scan">微信扫一扫</div>
				<div class="foot-text">
					<span>copyright © 2020-2021 ALL RIGHT RESERVED 宁德市千雪制冷设备有限公司 版权所有</span>
					<span>地址：宁德市蕉城区古溪新村23号</span>
					<span>备案号：闽ICP备20014190号</span>
					<span>电话：13616088271</span>
				</div>
			</div>
		</section>
		<!-- 大屏 -->
		<section class="foot uni-flex-column uni-flex-center" ref="contact" v-else>
			<div class="foot-content">
				<div class="foot-text">
					<span>copyright © 2020-2021 ALL RIGHT RESERVED 宁德市千雪制冷设备有限公司 版权所有</span>
					<span>地址：宁德市蕉城区古溪新村23号</span>
					<span>备案号：闽ICP备20014190号</span>
					<span>电话：13616088271</span>
				</div>
				<div class="foot-logo">
					<img src="/images/qrcode.png" style="width: 100%;height: 100%;"/>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
	import api from '@/utils/api.js';
	import headerNav from '@/components/header.vue';
	export default {
		name: 'news', // 新闻资讯
		components: {
			headerNav,
		},
		data() {
			return {
				is_vertical: false,  //false 小屏  true 大屏
				win_width: document.body.clientWidth,
				newsList: [],
				search: {
					categoryId: 1,
					current: 1,
					size: 10,
				},
				totalPages: 1,
				
			}
		},
		computed: {
			
		},
		created() {
			window.scrollTo({
			    left: 0,
			    top: 0,
			    behavior: 'smooth'
			})
			this.search.current = 1;
			this.api_menuList();
		},
		mounted() {
			/* 获取页面高宽 */
			// this.body_width = document.getElementById('news').offsetWidth;
			// this.body_height = document.getElementById('news').clientHeight;
			const that = this;
			window.onresize = () => {
				return (() => {
					window.screenWidth = document.body.clientWidth;
					that.win_width = window.screenWidth;
				})();
			};
			console.log(this.win_width);
			if (this.win_width < 768) {
				//小屏
				this.is_vertical = true;
			} else if (this.win_width >= 768) {
				//大屏
				this.is_vertical = false;
			}
		},
		watch: {
		},
		methods: {
			goDetail(item) {
				this.$router.push({
					name: 'newsDetail',
					query:{
						id:item.id
					}
				})
			},
			api_menuList() {
				api.menuList().then(res=>{
					console.log(res);
					if(res.code == 0){
						res.data.forEach(item => {
							if(item.title == "新闻资讯") {
								this.search.categoryId = item.id;
								this.api_newsList();
							}
						});
					}
				})
			},
			api_newsList() {
				api.newsList(this.search.categoryId,this.search).then(res=>{
					console.log(res);
					if(res.code == 0){
						this.newsList = res.data.records;
						this.totalPages = res.data.pages;
					}
				})
			},
			goList(val) {
				if(val == -1) {  //向后退一页
					if(this.search.current>1) {
						this.search.current--;
						this.api_newsList()
					}
				}
				else if(val == -2) {  //向前进一页
					if(this.search.current<this.totalPages) {
						this.search.current++;
						this.api_newsList()
					}
				}
				else {
					this.search.current = val;
					this.api_newsList()
				}
				
			}
		}
	}
</script>

<style scoped="" lang="less">
	.yx-h5 {
		font-size: 14px;
	}

	.news {
		height: 100%;
		overflow: auto;
		font-size: 20px;
		color: #bdbdbd;
		background-color: #F2F2F2;
	}
	
	.content-box {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
		.news-logo {
			width: 100%;
			height: 900px;
		}
		
		.news-logo-top {
			width: 1200px;
			margin: 50px 15px;
			text-align: left;
			.c1 {
				margin: 15px 0;
				color: #0E82D9;
				font-size: 30px;
				font-family: Montserrat;
				font-weight: bold;
				line-height: 30px;
			}
						
			.blue-line {
				margin-bottom: 30px;
				width: 100px;
				height: 4px;
				background: #0E82D9;
			}
			
			.news-list {
				margin-bottom: 30px;
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				.news-img {
					width: 500px;
					height: 200px;
				}
				.t2 {
					width: 600px;
					margin-top: 20px;
					font-size: 24px;
					font-family: Source Han Sans CN;
					font-weight: 500;
					color: #111111;
					line-height: 30px;
					display: -webkit-box;
					word-wrap:break-word;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 2;
					overflow: hidden;
				}
				.t3 {
					width: 600px;
					margin: 20px 0;
					font-size: 18px;
					font-family: Source Han Sans CN;
					font-weight: 500;
					color: #111111;
					line-height: 20px;
					display: -webkit-box;
					word-wrap:break-word;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 3;
					overflow: hidden;
				}
				.c2 {
					margin-top: 20px;
					color: #0E82D9;
					font-size: 18px;
					font-family: Montserrat;
					font-weight: bold;
					line-height: 30px;
				}
			}
			
			.foot-page {
				display: flex;
				justify-content: center;
				align-items: center;
				margin: 50px 0 20px;
				font-size: 20px;
				color: #0E82D9;
				
				.page-box {
					display: flex;
					justify-content: center;
					align-items: center;
					width: 60px;
					height: 40px;
					margin: 5px;
					background: #FFFFFF;
					border-radius: 6px;
					&:hover {
						background-color: #0E82D9;
					}
				}
				
				.page-num {
					display: flex;
					justify-content: center;
					align-items: center;
					width: 40px;
					height: 40px;
					margin: 5px;
					background: #FFFFFF;
					border-radius: 6px;
					
					&:hover {
						background-color: #0E82D9;
						color: #FFFFFF;
					}
				}
			}
			
		}
	}

	.friend {
		// margin-top: 30px;
		height: 50px;
		background-color: #F5F5F5;
		
		.friend-content {
			display: flex;
			flex-direction: wrap;
			width: 1200px;
			
			.friend-text {
				font-size: 16px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #000000;
				line-height: 24px;
			}
		}
	}
	
	.foot {
		height: 200px;
		background-color: #0E82D9;
		
		.foot-content {
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 1200px;
			
			.foot-text {
				display: flex;
				flex-direction: column;
				text-align: left;
				font-size: 16px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #FFFFFF;
				line-height: 24px;
				.span {
				}
			}
			
			.foot-logo {
				width: 142px;
				height: 142px;
			}
		}
	}

	/* 手机端 */
	@media (orientation:portrait) and (max-width:890px) {
		
		.news {
			overflow-y: auto;
			overflow-x: hidden;
		}
		
		.content-box {
			width: 100%;
			.news-logo {
				width: 100%;
				height: 300px !important;
			}
			
			.news-logo-top {
				width: 90%;
				margin: 30px 0;
				text-align: left;
				
				.c1 {
					margin: 15px 0;
					color: #0E82D9;
					font-size: 16px;
					font-family: Montserrat;
					font-weight: bold;
					line-height: 20px;
				}
							
				.blue-line {
					width: 50px;
					height: 2px;
					background: #0E82D9;
				}
				
				.news-list {
					margin-bottom: 30px;
					.news-img {
						width: 100%;
						height: 180px;
					}
					.t2 {
						font-size: 16px;
						width: 100%;
						font-family: Source Han Sans CN;
						font-weight: 500;
						color: #111111;
						line-height: 20px;
						display: -webkit-box;
						word-wrap:break-word;
						-webkit-box-orient: vertical;
						-webkit-line-clamp: 1;
						overflow: hidden;
					}
					.t3 {
						margin: 10px 0;
						width: 100%;
						font-size: 12px;
						font-family: Source Han Sans CN;
						font-weight: 500;
						color: #111111;
						line-height: 20px;
						display: -webkit-box;
						word-wrap:break-word;
						-webkit-box-orient: vertical;
						-webkit-line-clamp: 2;
						overflow: hidden;
					}
					.c2 {
						margin: 15px 0;
						color: #0E82D9;
						font-size: 14px;
						font-family: Montserrat;
						font-weight: bold;
						line-height: 20px;
					}
				}
				
				.foot-page {
					display: flex;
					justify-content: center;
					align-items: center;
					margin: 50px 0 20px;
					font-size: 14px;
					color: #0E82D9;
					
					.page-box {
						display: flex;
						justify-content: center;
						align-items: center;
						width: 40px;
						height: 40px;
						margin: 5px;
						background: #FFFFFF;
						border-radius: 6px;
					}
					
					.page-num {
						display: flex;
						justify-content: center;
						align-items: center;
						width: 40px;
						height: 40px;
						margin: 5px;
						background: #FFFFFF;
						border-radius: 6px;
						
						&:hover {
							background-color: #0E82D9;
							color: #FFFFFF;
						}
					}
				}
				
			}
		}
		.friend {
			margin-top: 30px;
			height: 30px;
			background-color: #F5F5F5;
			
			.friend-content {
				display: flex;
				flex-direction: wrap;
				width: 100%;
				margin-left: 10px;
				.friend-text {
					font-size: 14px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #000000;
					line-height: 20px;
				}
			}
		}
		.foot {
			// margin-top: 30px;
			height: 370px;
			background-color: #0E82D9;
			
			.foot-content {
				display: flex;
				flex-direction: column;
				width: 100%;
				.foot-scan {
					padding: 20px 0;
					text-align: center;
					font-size: 12px;
					color: #FFFFFF;
				}
				.foot-text {
					margin-top: 20px;
					text-align: center;
					font-size: 12px;
				}
				
				.foot-logo {
					width: 120px;
					height: 120px;
				}
			}
		}

		.header .content .nav {
			display: none !important;
		}

		.content {
			justify-content: space-between !important;
			padding: 0 20px;

			.clear {
				display: none;
			}

			.nav-min {
				display: flex !important;
			}

			.langs {
				display: none;
			}
		}
	}
</style>
